<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { clientsService } from '../../../../services/clients.service';
import DatePicker from "vue2-datepicker";
import moment from 'moment';
import VsToast from '@vuesimple/vs-toast';

/**
 * Blank page component
 */
export default {
    page: {
        title: "Clients",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, DatePicker },
    data() {
        return {
            clients: [],
            items: [],
            title: "Clients",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "createdAt",
            sortDesc: true,
            daterange: '',
            fields: [
                { key: "nom", label: 'Nom', sortable: true },
                { key: "prenom", label: 'Prénom', sortable: true },
                { key: "email", label: "Email", sortable: true },
                { key: "phone", label: "Téléphone", sortable: true },
                { key: "adresse", label: "Adresse", sortable: true },
                { key: "solde", label: "Solde", sortable: true },
                { key: "created", label: "Inscription", sortable: true },
                { key: "state", label: "Etat", sortable: true },
                // { key: "state", label: "Etat", sortable: true },
                { key: 'options', sortable: false, label: 'Options' }
            ]
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.clients.length;
        }
    },
    mounted() {
        this.getClients();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        async getClients() {
            this.clients = await clientsService.getClient();
        },
         showDetail(item) {
            this.$router.push(`/clients/${item.id}/detail`);
        },
        async chooseDate(e) {
            
            const params = `?debut=${moment(e[0]).format("YYYY-MM-DD")}&fin=${moment(e[1]).format("YYYY-MM-DD")}`;
            this.clients = await clientsService.getClient(params);
        },
        async clearable() {
            this.clients = await clientsService.getClient();
        },
        async desactivate(id) {
            const resp = await clientsService.desactive(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Mobinaute désactivé de la plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getClients();
            }
        },
        async activate(id) {
            const resp = await clientsService.active(id);
            if (resp.status >= 400) {
                VsToast.show({
                    title: 'Une erreur s\'est produite! Veuillez réssayer plus tard',
                    message: '',
                    variant: 'error',
                });
            } else {
                VsToast.show({
                    title: 'Mobinaute disponible sur plateforme',
                    message: '',
                    variant: 'success',
                });
                this.getClients();
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div class="col-md-4">
                <date-picker
                    v-model="daterange"
                    format="DD/MM/YYYY"
                    range
                    append-to-body
                    confirm
                    @clear="clearable"
                    @confirm="chooseDate"
                    placeholder="Selectionner votre rangé de date"
                ></date-picker>
            </div>
           </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="clients" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                <template v-slot:cell(created)="r">
                                        {{ r.item.created | defautTableDateTime2 }}
                                </template>
                                <template v-slot:cell(solde)="r">
                                    <span
                                        class="badge"
                                        :class="{
                                            'bg-success': true,
                                        }">  {{ r.item.compte != null ? r.item.compte.solde : 0 | currencyFormat }} </span>
                                       
                                </template>
                                <template v-slot:cell(state)="r">
                                    <span style="margin-right: 3px;"
                                        :class="r.item.state ? 'badge bg-success' : 'badge bg-danger'"
                                        > {{ r.item.state ? 'Actif' : 'Désactivé' }}</span>
                                </template>
                                <template v-slot:cell(options)="r">
                                    <button @click="showDetail(r.item)" title="Détail des actions" class="btn btn-warning btn-sm" style="margin-right: 5px;"><i class="fa fa-eye"></i></button>
                                    <button v-if="r.item.state" @click="desactivate(r.item.id)" title="Désactiver" class="btn btn-danger btn-sm"><i class="fa fa-lock"></i></button>
                                    <button v-if="!r.item.state" @click="activate(r.item.id)" title="Activer" class="btn btn-success btn-sm"><i class="fa fa-unlock"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>